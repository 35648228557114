import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { GetClassOrderStatus, GetOrderStatus } from '../../API/APIRequests';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { mainUrl } from '../../Redux/BaseUrls';
import OrderFailure from './OrderFailure';
import OrderSuccess from './OrderSuccess';
import ClassOrderSuccess from './ClassOrderSuccess';
import ClassOrderFailure from './ClassOrderFailure';
import SubscriptionOrderSuccess from './SubscriptionOrderSuccess';
import SubscriptionOrderFailure from './SubscriptionOrderFailure';
import StoreOrderFailure from './StoreOrderFailure';
import StoreOrderSuccess from './StoreOrderSuccess';
import GoHomeBtn from '../../Components/GoHomeBtn';
import MainLayout from '../../Components/MainLayout';

export default function OrderStatus() {
  const { i18n } = useTranslation();
  const { Status } = useParams();
  const Location = useLocation();
  const cash = Location.state ? Location.state.cash : false;

  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const cashorderstatus = useSelector(
    (state) => state.PersistedReducer.OrderStatus
  );
  const InvoiceId = useSelector((state) => state.PersistedReducer.InvoiceId);

  const [OrderStatus, SetOrderStatus] = useState(cashorderstatus);

  useEffect(() => {
    if (cash !== true) {
      GetOrderStatus(
        {
          countryID: UserSelectedData.Country.id,
          invoice_id: InvoiceId,
        },
        (res) => {
          SetOrderStatus(res);
        }
      );
    }
  }, []);

  useEffect(() => {
    console.log(cash, OrderStatus, Status);
  }, [cash, OrderStatus]);

  return (
    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className='flex flex-col items-center h-screen'>
      <Header />
      {OrderStatus.success === true ?
        <div className='w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow'>
          {Status === 'success' &&
            <>
              {OrderStatus.data.category === 'Sports' ?
                <OrderSuccess data={OrderStatus} />
                :
                OrderStatus.data.category === 'Classes' ?
                  <ClassOrderSuccess data={OrderStatus} />
                  :
                  OrderStatus.data.category === 'Subscriptions' ?
                    <SubscriptionOrderSuccess data={OrderStatus} />
                    :
                    OrderStatus.data.category === 'Store' &&
                    <StoreOrderSuccess data={OrderStatus} />
              }
              
            </>
          }
          <GoHomeBtn containerClass='w-full' />
        </div>
        : OrderStatus.success === false ?
          <div className='w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow'>
            {Status === 'failure' &&
              <>
                {OrderStatus.data.category === 'Sports' ?
                  <OrderFailure data={OrderStatus} />
                  :
                  OrderStatus.data.category === 'Classes' ?
                    <ClassOrderFailure data={OrderStatus} />
                    :
                    OrderStatus.data.category === 'Subscriptions' ?
                      <SubscriptionOrderFailure data={OrderStatus} />
                      :
                      OrderStatus.data.category === 'Store' &&
                      <StoreOrderFailure data={OrderStatus} />
                }
              </>
            }
            
            <GoHomeBtn containerClass='w-full' />
          </div>
          :
          <LoadingSpinner />
      }

      
      
      <Footer />
    </div>
  )
}
