import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GreyLine from '../../../Components/GreyLine';

import { ReactComponent as Calender } from '../../../Assets/Icons/SubscriptionCategory/Calender.svg';
import { ReactComponent as LeftArrow } from '../../../Assets/Icons/LeftArrow.svg';
import { ReactComponent as RightArrow } from '../../../Assets/Icons/RightArrow.svg';

import CustomButton from '../../../Components/CustomButton';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveUserDataAction} from '../../../Redux/Actions';
import { useNavigate } from 'react-router-dom';
import './Style.css'


function Content(props) {
    const {t,i18n}=useTranslation()
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const UserSelectedData = useSelector(state => state.PersistedReducer.UserSelectedData)
    const Today=new Date()
    const [SelectedDate,SetSelectedDate]=useState('')
    const [ShowCalender,SetShowCalender]=useState(false)
    const [DateFromCalender,SetDateFromCalender]=useState(false)
    


  // display tomorrow date
  const AddDaysToCurrentDate = (val = 1) => {
    const Tomorrow = new Date();
    Tomorrow.setDate(Tomorrow.getDate() + val);

    return Tomorrow;
  };


    const HandelContinue=()=>{
        if(SelectedDate){
            dispatch(SaveUserDataAction({
                ...UserSelectedData , 
                SelectedSubscriptionDate:SelectedDate
            }))
            props.closeDrawer()

            navigate('/OrderReview')
        }
    }


    

  return (
    <div className="flex justify-center py-8 lg:py-5">
      <div className="w-full px-3 lg:px-0 lg:w-1/3">
        {ShowCalender ? (
          <div>
            <div className="flex">
              <div
                className="cursor-pointer"
                onClick={() => {
                  SetShowCalender(false);
                }}
              >
                {i18n.language === 'ar' ? (
                  <RightArrow width="9.353" height="13.102" />
                ) : (
                  <LeftArrow width="9.353" height="13.102" />
                )}
              </div>
              <div className="flex justify-center w-full mb-8">
                <Calender width="20.354" height="22.955" />
                <p className="px-2">{t('Start date')}</p>
              </div>
            </div>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                  <CalendarPicker
                    minDate={Today}
                    maxDate={AddDaysToCurrentDate(
                      UserSelectedData.SelectedSubscription.max_days
                    )}
                    date={SelectedDate ? SelectedDate : new Date()}
                    onChange={(newDate) => {
                      SetSelectedDate(newDate);
                      SetDateFromCalender(true);
                    }}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>

            {/* <div className='py-5'>
                            <CustomButton onclick={()=>{HandelContinue()}} BtnValue={t("Continue")} ContainerClass='bg-primary_BG rounded-xl text-sm text-white cursor-pointer py-1 text-center'/>
                        </div> */}
          </div>
        ) : (
          <div>
            <div className="flex items-center mb-8">
              <Calender width="20.354" height="22.955" />
              <p className="px-2">{t('Start date')}</p>
            </div>

            {/* today and tomorrow dates */}
            <div className="flex justify-between text-sm">
              <div className="flex">
                <input
                  type="radio"
                  name="Date"
                  onClick={() => SetSelectedDate(Today)}
                ></input>
                <p className="px-2">
                  {t('Start today,')}
                  {Today.toDateString()}
                </p>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  name="Date"
                  onClick={() => SetSelectedDate(AddDaysToCurrentDate())}
                ></input>
                <p className="px-2">
                  {t('Start Tomorrow,')}
                  {AddDaysToCurrentDate().toDateString()}
                </p>
              </div>
            </div>

            <GreyLine className="my-3" />

            {/* date from calender */}
            {UserSelectedData.SelectedSubscription.pre_starting_date === 1 && (
              <>
                <div className="flex text-sm mb-3">
                  <input
                    type="radio"
                    name="Date"
                    onClick={() => SetShowCalender(true)}
                  ></input>
                  <p className="px-2">
                    {t('Select a Date to start your subscription')}
                  </p>
                </div>

                {DateFromCalender === true && (
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <p className="text-InputGray text-sm">
                        {t('Selected date')}
                      </p>
                      <p className="px-2 text-primary_BG">
                        {SelectedDate.toDateString()}
                      </p>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          SetShowCalender(true);
                        }}
                        className="bg-SearchGrey px-2 rounded-full"
                      >
                        {t('Change')}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {/* continue btn */}
        {SelectedDate ? (
          <div className="py-5">
            <CustomButton
              onclick={() => {
                HandelContinue();
              }}
              BtnValue={t('Continue')}
              ContainerClass="bg-primary_BG rounded-xl text-sm text-white cursor-pointer py-1 text-center"
            />
          </div>
        ) : (
          <div className="py-5">
            <CustomButton
              BtnValue={t('Continue')}
              ContainerClass="bg-GreyBG rounded-xl text-sm text-white cursor-pointer py-1 text-center"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Content;
