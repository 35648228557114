import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackBTN from '../../Components/BackBTN';
import CustomButton from '../../Components/CustomButton';
import { mainUrl } from '../../Redux/BaseUrls';
import {
  IsGuestUserAction,
  SaveCodeVerificationDataAction,
  SavePhoneAction,
  SaveTokenAction,
  SaveUserInfoAction,
  ToggleShowHideAction,
} from '../../Redux/Actions';
import Error from './Error';

export default function Content() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const persistedData = useSelector((state) => state.PersistedReducer);

  const [InputType, SetInputType] = useState('text');
  const [UserData, SetUserData] = useState({
    name: '',
    email: '',
    phone: '',
    gender: '',
    password: '',
    password_confirmation: '',
    date_of_birth: '',
  });

  const [RegisterRes, SetRegisterRes] = useState({});

  const HandelChangeUserData = (nm, val) => {
    SetUserData((prev) => ({
      ...prev,
      [nm]: val,
    }));
  };

  // send register req
  const SendreqRegister = () => {
    if (UserData.phone.length < 8) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: 'Please Enter a Valid Phone',
        })
      );
    } else {
      mainUrl
        .post(
          `register`,
          {
            ...UserData,
            country_code: UserSelectedData.Country.code,
            ...(persistedData.TempID ? { tempId: persistedData.TempID } : {}),
            // tempId:persistedData.TempID,
            store: UserSelectedData.Category === 'Store' ? 'true' : 'false',
          },
          {
            headers: {
              country: UserSelectedData.Country.id,
              lang: i18n.language,
            },
          }
        )
        .then((response) => response.data)
        .then((data) => SetRegisterRes(data));
    }
  };

  useEffect(() => {
    // console.log('reg',RegisterRes)

    if (RegisterRes.success === true) {
      // console.log(RegisterRes)
      dispatch(
        SaveUserInfoAction({ ...RegisterRes.data, password: UserData.password })
      );
      dispatch(IsGuestUserAction(false));
      // dispatch(SaveTokenAction())

      // dispatch(SavePhoneAction({
      //   phone:UserData.phone
      // }))
      navigate('/OTP/Code');
    } else if (RegisterRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: t(RegisterRes.message),
        })
      );
    }
  }, [RegisterRes]);

  useEffect(() => {
    console.log('usr  data', UserData);
  }, [UserData]);

  return (
    <div className="relative h-full">
      <BackBTN
        text={
          <div className="flex justify-center">
            <p className="w-1/2">
              {t('Create your Form account to continue your booking')}
            </p>
          </div>
        }
      />

      <input
        onChange={(e) => {
          HandelChangeUserData('name', e.target.value);
        }}
        type="text"
        placeholder={t('Name')}
        className="rounded-lg px-3 py-1 mb-5 text-sm bg-gray outline-none w-full"
      />

      <input
        onChange={(e) => {
          HandelChangeUserData('email', e.target.value);
        }}
        type="email"
        placeholder={t('Email')}
        className="rounded-lg px-3 py-1 mb-5 text-sm bg-gray outline-none w-full"
      />

      <input
        onChange={(e) => {
          HandelChangeUserData('phone', e.target.value);
        }}
        type="tel"
        placeholder={t('Mobile Number')}
        className="rounded-lg px-3 py-1 mb-5 text-sm bg-gray outline-none w-full"
      />

      {/* gender */}
      <div className="grid grid-cols-2 justify-items-center gap-x-10 mb-5">
        <CustomButton
          onclick={(e) => {
            HandelChangeUserData('gender', 'male');
          }}
          // ContainerStyle={{maxWidth:'10rem'}}
          ContainerClass={
            UserData.gender === 'male'
              ? 'w-full border border-SearchGrey rounded-lg text-sm cursor-pointer py-1 bg-primary_BG text-white'
              : 'w-full border border-SearchGrey rounded-lg text-sm cursor-pointer py-1 '
          }
          BtnValue={t('Male')}
        />

        <CustomButton
          onclick={(e) => {
            HandelChangeUserData('gender', 'female');
          }}
          // ContainerStyle={{maxWidth:'10rem'}}
          ContainerClass={
            UserData.gender === 'female'
              ? 'w-full border border-SearchGrey rounded-lg text-sm cursor-pointer py-1 bg-primary_BG text-white'
              : 'w-full border border-SearchGrey rounded-lg text-sm cursor-pointer py-1 '
          }
          BtnValue={t('Female')}
        />
      </div>

      <input
        onChange={(e) => {
          HandelChangeUserData('password', e.target.value);
        }}
        type="password"
        placeholder={t('Password')}
        className="rounded-lg px-3 py-1 mb-5 text-sm bg-gray outline-none w-full"
      />

      <input
        onChange={(e) => {
          HandelChangeUserData('password_confirmation', e.target.value);
        }}
        type="password"
        placeholder={t('Re-enter Password')}
        className="rounded-lg px-3 py-1 mb-5 text-sm bg-gray outline-none w-full"
      />

      {/* date of birth */}
      <div className="flex items-center justify-between mb-8">
        <div class="w-1/3  lg:w-1/4">
          <p class="text-primary_BG text-left rtl:text-right">Date of Birth</p>
        </div>

        <input
          onChange={(e) => {
            HandelChangeUserData('date_of_birth', e.target.value);
          }}
          type={InputType}
          placeholder="MM | DD | YYYY"
          class="
                py-2
                px-5
                bg-gray
                rounded-lg
                w-2/3
                lg:w-3/4
                mb-3
                outline-none
              "
          onFocus={() => {
            SetInputType('date');
          }}
          onBlur={() => {
            SetInputType('text');
          }}
          // onfocus="(this.type='date')"
        />
      </div>

      {/* continue btn */}
      <CustomButton
        onclick={() => SendreqRegister()}
        ContainerClass="w-full bg-primary_BG rounded-lg text-sm text-white py-1 my-5"
        BtnValue={t('Continue')}
      />
    </div>
  );
}
