import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddToCart } from '../../../API/APIRequests';
import BackBTN from '../../../Components/BackBTN';
import Counter from '../../../Components/Counter';
import GeneralCarousel from '../../../Components/GeneralCarousel';
import GreyLine from '../../../Components/GreyLine';
import { SaveTempIDAction, ToggleShowHideAction } from '../../../Redux/Actions';
import ChangeVendorModal from './ChangeVendorModal'

function Content(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const persistedReducer = useSelector((state) => state.PersistedReducer);
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const [TotalPrice, SetTotalPrice] = useState(
    parseFloat(props.data.data.price)
  );
  const [SelectedAddons, SetSelectedAddons] = useState([]);
  const [RequiredOrOptional, SetRequiredOrOptional] = useState(() => {
    let arr = [];
    props.data.data.addons.map((i) => {
      arr.push({ addonID: i.id, selection: i.selection });
    });

        return arr
    });
    const [Msg,SetMsg]=useState('')
    const [Quantity,SetQuantity]=useState(1)
    const [AddToCartRes,SetAddToCartRes]=useState({})

    // when add item from diffrent vendor
    const [ChangeVendor,SetChangeVendor]=useState(false)
    const [OpenVendorModal,SetOpenVendorModal]=useState(false)





  // when addon is selceted or changed
  const HandelSelectAddon = (addonID, type, optionID, price) => {
    if (SelectedAddons.length === 0) {
      SetSelectedAddons([
        { id: addonID, options: [{ id: optionID, quantity: 1, price: price }] },
      ]);
      HandelUpdateTotal(price);
    } else {
      if (type === 'radio') {
        UpdateRadionSelectAddons(addonID, optionID, price);
      } else if (type === 'checkbox') {
        UpdateCheckBoxAddon(addonID, optionID, price);
      } else if (type === 'dropdown') {
        let opID = optionID.split('-')[0];
        let prc = optionID.split('-')[1];
        console.log(opID, prc);

        UpdateRadionSelectAddons(addonID, opID, prc);
      }
    }
  };

  const UpdateRadionSelectAddons = (addonID, optionID, price) => {
    let addonExist = checkIfAddonExist(addonID);
    // console.log(addonExist,addonID)
    if (addonExist.Exist) {
      let items = [...SelectedAddons];
      items[addonExist.index] = {
        id: addonID,
        options: [{ id: optionID, price: price, quantity: 1 }],
      };
      // update total
      HandelUpdateTotal(price, items[addonExist.index].options[0].price);
      // update addon
      SetSelectedAddons(items);
    } else {
      console.log(addonExist, addonID);
      // update total
      HandelUpdateTotal(price);
      // update addons
      SetSelectedAddons((prev) => [
        ...prev,
        {
          id: addonID,
          options: [{ id: optionID, price: price, quantity: 1 }],
        },
      ]);
    }
  };

  const UpdateCheckBoxAddon = (addonID, optionID, price) => {
    let addonExist = checkIfAddonExist(addonID);

    if (addonExist.Exist) {
      let optionExist = false;
      let Options = [];

      // check if option exist
      for (
        let j = 0;
        j < SelectedAddons[addonExist.index].options.length;
        j++
      ) {
        if (SelectedAddons[addonExist.index].options[j].id === optionID) {
          optionExist = true;
          HandelUpdateTotal(
            0,
            SelectedAddons[addonExist.index].options[j].price
          );
        }
      }

      // if option exsist and remove it if not add it
      if (optionExist) {
        Options = SelectedAddons[addonExist.index].options.filter((option) => {
          if (option.id !== optionID) {
            return option;
          }
        });
      } else {
        Options = [
          ...SelectedAddons[addonExist.index].options,
          { id: optionID, quantity: 1, price: price },
        ];
        HandelUpdateTotal(price);
      }

      // modify state
      if (Options.length === 0) {
        let items = [...SelectedAddons];
        items.splice(addonExist.index, 1);
        SetSelectedAddons(items);
      } else {
        let items = [...SelectedAddons];
        items[addonExist.index] = {
          id: addonID,
          price: price,
          options: Options,
        };
        SetSelectedAddons(items);
      }
    } else {
      SetSelectedAddons((prev) => [
        ...prev,
        {
          id: addonID,
          options: [{ id: optionID, quantity: 1, price: price }],
        },
      ]);

      HandelUpdateTotal(price);
    }
  };

  // check if addon is already selected
  const checkIfAddonExist = (addonID) => {
    let addonExist = false;
    let indx = -1;

    for (let i = 0; i < SelectedAddons.length; i++) {
      if (SelectedAddons[i].id === addonID) {
        addonExist = true;
        indx = i;
      }
    }

    return { Exist: addonExist, index: indx };
  };

  // update total
  const HandelUpdateTotal = (NewPrice, prevPrice = 0) => {
    SetTotalPrice(
      (prev) => prev + parseFloat(NewPrice) - parseFloat(prevPrice)
    );
  };

  // quantity change
  const HandelIncDecQuantity = (plus) => {
    if (plus === true) {
      SetQuantity((prev) => prev + 1);
    }

    if (plus === false) {
      if (Quantity > 1) {
        SetQuantity((prev) => prev - 1);
      }
    }
  };

  // handel add to cart
  const HandelAddToCart = () => {
    let RequiredSelected = HandelCheckRequiredOptional();

    // console.log('RequiredSelected',RequiredSelected)

    if (RequiredSelected === true) {
      AddToCart(
        {
          countryID: UserSelectedData.Country.id,
          data: {
            // ...(persistedReducer.Loggedin ?
            //     {user_id:persistedReducer.Token}
            //     :{tempId:persistedReducer.TempID}
            // ),

            ...(persistedReducer.Loggedin && {
              user_id: persistedReducer.UserInfo.user.id,
            }),
            tempId: persistedReducer.TempID ? persistedReducer.TempID : null,
            // if vendor is diffrnt send it with true
            change: ChangeVendor,
            vendor_id: props.data.data.vendor_id,
            item_id: props.data.data.id,
            note: Msg,
            quantity: Quantity,
            addons: SelectedAddons,
          },
        },
        (res) => {
          SetAddToCartRes(res);
        }
      );
    } else if (RequiredSelected === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: t('Please select all required addons'),
        })
      );
    }
  };

  // check mendatory or optional
  const HandelCheckRequiredOptional = () => {
    // console.log(RequiredOrOptional)
    let RequiredSelected = true;

    for (let i = 0; i < RequiredOrOptional.length; i++) {
      if (RequiredOrOptional[i].selection === 'mandatory') {
        let CheckExist = checkIfAddonExist(RequiredOrOptional[i].addonID);
        if (CheckExist.Exist === false) {
          RequiredSelected = false;
        }
      }
    }

    return RequiredSelected;
  };

  // check add to cart res
  useEffect(() => {
    if (AddToCartRes.success === true) {
      if (AddToCartRes.data && persistedReducer.TempID === '') {
        if (AddToCartRes.data.tempId) {
          dispatch(SaveTempIDAction(AddToCartRes.data.tempId));
        }
      }

      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'success',
          Msg: AddToCartRes.message,
        })
      );

      navigate('/AllProducts/null');
    }

    // if item is from diffrent vendor
        if(AddToCartRes.success ===false && AddToCartRes.status === "201"){
            SetOpenVendorModal(true)
        }
        else if (AddToCartRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: AddToCartRes.message,
        })
      ); 
    }
  }, [AddToCartRes]);

  useEffect(() => {
    console.log('SelectedAddons', SelectedAddons);
    console.log('TotalPrice', TotalPrice);
  }, [SelectedAddons, TotalPrice]);

  return (
    <div>
      <BackBTN />

      <GeneralCarousel slidesArray={props.data.data.images} />

      {/* name and price */}
      <div className="flex justify-between my-3 text-sm">
        <div>
          <p className="text-start text-primary_BG">{props.data.data.name}</p>
          <p className="text-start truncate text-DarkGrey">
            {props.data.data.categories}
          </p>
        </div>

        <div>
          <p className="text-primary_BG">{props.data.data.price}</p>
        </div>
      </div>

      <GreyLine className="my-3"></GreyLine>

      {/* addons */}
      <div>
        {props.data.data.addons.map((itm) => {
          return (
            <div key={itm.id} className="text-start text-sm mb-7">
              <div className="flex mb-2">
                <p className="text-primary_BG">{itm.name}</p>
                {itm.selection === 'mandatory' && (
                  <p className="text-xs text-red-600 px-2">(Required)</p>
                )}
                {itm.selection === 'optional' && (
                  <p className="text-xs  px-2">(Optional)</p>
                )}
              </div>

              {(itm.type === 'radio' || itm.type === 'checkbox') && (
                <div className="px-2">
                  {itm.options.map((i) => {
                    return (
                      <div
                        className="flex items-center justify-between mb-1"
                        key={i.id}
                      >
                        <div className="flex items-center">
                          <input
                            disabled={i.stock === 0 ? 'disabled' : ''}
                            onChange={() =>
                              HandelSelectAddon(itm.id, itm.type, i.id, i.price)
                            }
                            id={i.name + i.id}
                            type={itm.type}
                            name={itm.id}
                          ></input>
                          <lable
                            className={
                              i.stock === 0 ? 'opacity-50 px-1' : 'px-1'
                            }
                          >
                            {i.name}
                          </lable>
                        </div>
                        <p
                          className={
                            i.stock === 0
                              ? 'opacity-50 text-primary_BG'
                              : 'text-primary_BG'
                          }
                        >
                          {i.price}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}

              {itm.type === 'dropdown' && (
                <div className="px-2">
                  <select
                    onChange={(e) =>
                      HandelSelectAddon(itm.id, itm.type, e.target.value)
                    }
                    className="outline-none border-b w-full border-slate-600"
                  >
                    <option value="" selected disabled hidden>
                      {t('Select please')}
                    </option>
                    {itm.options.map((i) => {
                      return (
                        <option
                          disabled={i.stock === 0 ? 'disabled' : ''}
                          key={i.id}
                          value={`${i.id}-${i.price}`}
                        >
                          {i.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* notes */}
      <div className="w-full text-start">
        <p className="text-sm text-primary_BG mb-2">{t('Extra notes')}</p>
        <textarea
          onChange={(e) => SetMsg(e.target.value)}
          className="bg-SearchGrey rounded-md w-full text-sm p-3 outline-none"
        />
      </div>

      {/* quantity meter */}
      <div className="flex justify-between gap-x-2 py-3">
        <p className="text-sm text-primary_BG mb-2">{t('Quantity')}</p>
        <Counter
          val={Quantity}
          inc={() => HandelIncDecQuantity(true)}
          dec={() => HandelIncDecQuantity(false)}
        />
      </div>

      {/* add to cart btn */}
      <div
        onClick={() => HandelAddToCart()}
        className="flex justify-between items-center text-white bg-primary_BG rounded-lg cursor-pointer py-2 px-3  mt-8"
      >
        <p>{t('Add To Cart')}</p>
        <p>{TotalPrice}</p>
      </div>


            {/* change vendor modal */}
            <ChangeVendorModal OpenModal={OpenVendorModal} OnClose={()=>SetOpenVendorModal(false)} ChangeVendor={()=>SetChangeVendor(true)}/>

    </div>
  );
}

export default Content;
