import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ForgetPass } from '../../API/APIRequests';
import BackBTN from '../../Components/BackBTN';
import CustomButton from '../../Components/CustomButton';
import {
  IsGuestUserAction,
  SaveTokenAction,
  SaveUserInfoAction,
  ToggleShowHideAction,
  UserLoggedInAction,
} from '../../Redux/Actions';
import { mainUrl } from '../../Redux/BaseUrls';
import { ExecuteBasedOnCat } from '../../Utils/Utils';

export default function Content() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [Credentials, SetCredentials] = useState({ email: '', password: '' });
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const persistedData = useSelector((state) => state.PersistedReducer);
  const [LoginRes, SetLoginRes] = useState({});
  const [ForgetPassRes, SetForgetPassRes] = useState({});

  const HandelInputChange = (val, nm) => {
    SetCredentials((prev) => ({
      ...prev,
      [nm]: val,
    }));
  };

  const HandelLogin = () => {
    mainUrl
      .post(
        `login`,
        {
          email: Credentials.email,
          password: Credentials.password,
          ...(persistedData.TempID ? { tempId: persistedData.TempID } : {}),
          // tempId:persistedData.TempID,
          store: UserSelectedData.Category === 'Store' ? 'true' : 'false',
        },
        {
          headers: {
            country: UserSelectedData.Country.id,
            lang: i18n.language,
          },
        }
      )
      .then((response) => response.data)
      .then((data) => SetLoginRes(data));
  };

  // check login res
  useEffect(() => {
    if (LoginRes.success === true) {
      dispatch(SaveUserInfoAction(LoginRes.data));
      dispatch(IsGuestUserAction(false));
      dispatch(UserLoggedInAction(true));
      dispatch(SaveTokenAction(LoginRes.data.access_token));

      // navigate based on cat
      ExecuteBasedOnCat(
        UserSelectedData.CategoryId,
        () => navigate('/ReviewOrder'),
        () => navigate('/OrderReview'),
        () => navigate('/OrderReview'),
        () => navigate('/ReviewOrder/Store')
      );

      // if(UserSelectedData.Category === 'Sports'){
      //     navigate('/ReviewOrder')
      // }
      // else if(UserSelectedData.Category === 'Store'){
      //     navigate('/ReviewOrder/Store')
      // }
      // else{
      //     navigate('/OrderReview')
      // }
    } else if (LoginRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: LoginRes.message,
          onclose: () => {
            if (LoginRes.data) {
              dispatch(SaveUserInfoAction(LoginRes.data));
              dispatch(SaveTokenAction(LoginRes.data.access_token));
              navigate('/OTP/PhoneNumber');
            }
          },
        })
      );
    }
  }, [LoginRes]);

  const HandelForgetPassword = () => {
    if (Credentials.email) {
      ForgetPass(
        {
          countryID: UserSelectedData.Country.id,
          data: {
            email: Credentials.email,
          },
        },
        (res) => {
          SetForgetPassRes(res);
        }
      );
    } else {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: 'please enter your email to continue',
          onclose: () => {},
        })
      );
    }
  };

  useEffect(() => {
    if (ForgetPassRes.success === true) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'success',
          Msg: ForgetPassRes.message,
          onclose: () => {},
        })
      );
    }
    if (ForgetPassRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: ForgetPassRes.message,
          onclose: () => {},
        })
      );
    }
  }, [ForgetPassRes]);

  return (
    <div>
      <BackBTN
        text={
          <div className="flex justify-center">
            <p className="w-1/2">{t('Create your account to continue')}</p>
          </div>
        }
      />

      <input
        onChange={(e) => {
          HandelInputChange(e.target.value, 'email');
        }}
        type="email"
        placeholder={t('Email')}
        className="rounded-lg px-3 py-1 mb-5 text-sm bg-gray outline-none w-full"
      />

      <input
        onChange={(e) => {
          HandelInputChange(e.target.value, 'password');
        }}
        type="password"
        placeholder={t('Password')}
        className="rounded-lg px-3 py-1 mb-5 text-sm bg-gray outline-none w-full"
      />

      <CustomButton
        onclick={() => HandelLogin()}
        ContainerClass="bg-primary_BG rounded-lg text-sm text-white py-1"
        BtnValue={t('Login')}
      />

      <div className="flex justify-between text-sm my-6">
        <p
          onClick={() => HandelForgetPassword()}
          className="cursor-pointer text-red-500"
        >
          {t('Forgot Password?')}
        </p>
        <p
          onClick={() => navigate('/CreateAccount')}
          className="cursor-pointer text-primary_BG"
        >
          {t('Register Now')}
        </p>
      </div>

      <div className="flex items-center">
        <div className="bg-gray grow h-px"></div>
        <p className="px-1">{t('Or')}</p>
        <div className="bg-gray grow h-px"></div>
      </div>

      <CustomButton
        onclick={() => navigate('/GuestLogin')}
        ContainerClass="border border-SearchGrey rounded-lg text-sm cursor-pointer py-1 my-5"
        BtnValue={t('Continue As Guest')}
      />
    </div>
  );
}
