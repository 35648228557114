import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GetSubscriptionDetails } from '../../../API/APIRequests';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { SaveUserDataAction } from '../../../Redux/Actions';

import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function ViewSubscription(props) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [Details, SetDetails] = useState({});

  useEffect(() => {
    // remove selected date
    dispatch(
      SaveUserDataAction({
        ...UserSelectedData,
        SelectedSubscriptionDate: '',
      })
    );

        if(UserSelectedData.Country){
            GetSubscriptionDetails({
                countryID:UserSelectedData.Country.id,
                data:{
                    vendor_id:id,
                    category:UserSelectedData.SelectedSubscriptionType.id,
                    ...(UserSelectedData.SelectedGender ?  {gender:UserSelectedData.SelectedGender }:{}),
                    ...(UserSelectedData.SelectedArea ?  {area:UserSelectedData.SelectedArea.id }:{})
                    
                }
            },(res)=>{ SetDetails(res) })
        }
       
    },[i18n.language,UserSelectedData.Country])


    useEffect(()=>{
        console.log(Details)
    },[Details])


    


  return (
    <MainLayout Cat='Subscriptions'>
      {Details.success === true ? (
        <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
          <Content Data={Details} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default ViewSubscription;