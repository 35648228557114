import { mainUrl } from '../Redux/BaseUrls';
import i18n from '../i18n/Config';

export const GetAllTypes = (payload, successCallBack) => {
  mainUrl
    .get(`types`,{
      headers: {
        'Accept-Language': i18n.language,
      },
    }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('get all sports', error);
    });
};

export const GetAllSports = (payload, successCallBack) => {
  mainUrl
    .get(`sports`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('get all sports', error);
    });
};

export const GetAllAreas = (payload, successCallBack) => {
  mainUrl
    .get(`areas`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('get all areas', error);
    });
};

export const GetAllVenues = (payload, successCallBack) => {
  mainUrl
    .post(
      `get-venues`,
      {},
      {
        headers: {
          country: payload.country,
          'Accept-Language': i18n.language,
        },
        params: {
          date: payload.date,
          sport: payload.sport,
          ...(payload.area ? { area: payload.area } : {}),
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('get all venues', error);
    });
};

export const UpdateAccountSettings = (payload, successCallBack) => {
  mainUrl
    .post(
      `account-setting`,
      { ...payload.data },
      {
        headers: {
          country: payload.country,
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${payload.token}`,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('update account', error);
    });
};

export const UpdateAvatar = (payload, successCallBack) => {
  const formData = new FormData();
  formData.append('image', payload.data, payload.data.name);
  // console.log('file after',formData.getAll("file"))

  mainUrl
    .post(`update-avatar`, formData, {
      headers: {
        country: payload.country,
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${payload.token}`,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('update account', error);
    });
};

export const ResetPassword = (payload, successCallBack) => {
  mainUrl
    .post(
      `reset-password`,
      { ...payload.data },
      {
        headers: {
          country: payload.country,
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${payload.token}`,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('update account', error);
    });
};



export const GetOrderStatus=(payload,successCallBack)=>{
    mainUrl.get(`appointment/payment/status`,
    {headers:
        {
        country:payload.countryID,
        'Accept-Language':i18n.language
        },
        params: {
          invoice_id: payload.invoice_id,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('get order status', error);
    });
};

export const GetSportsHistory = (payload, successCallBack) => {
  mainUrl
    .get(`venue/appointment-history`, {
      headers: {
        Authorization: payload.Authorization,
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('sports history', error);
    });
};

//////////////////////////////////////////////////////// class ////////////////////////
export const GetClassTypes = (payload, successCallBack) => {
  mainUrl
    .get(`category/class`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('get order status', error);
    });
};

export const GetAllClasses = (payload, successCallBack) => {
  mainUrl
    .post(
      `classes`,
      { ...payload.data },
      {
        headers: {
          country: payload.countryID,
          'Accept-Language': i18n.language,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('get order status', error);
    });
};

export const GetClassDetails = (payload, successCallBack) => {
  mainUrl
    .get(`class/details`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: { ...payload.data },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('class details', error);
    });
};

export const GetOrderReviewData = (payload, successCallBack) => {
  mainUrl
    .get(`class/proceed-to-booking`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('order review details', error);
    });
};

export const GetProceedToPayment = (payload, successCallBack) => {
  mainUrl
    .post(
      `class/proceed-to-payment`,
      { ...payload.data },
      {
        headers: {
          country: payload.countryID,
          'Accept-Language': i18n.language,
            'Accept-Language':i18n.language,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('order review details', error);
    });
};

export const GetClassesHistory = (payload, successCallBack) => {
  mainUrl
    .get(`class/booking-history`, {
      headers: {
        Authorization: payload.Authorization,
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('class details', error);
    });
};

export const ExploreMoreClasses = (payload, successCallBack) => {
  mainUrl
    .get(`class/show-more`, {
      headers: {
        Authorization: payload.Authorization,
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('class details', error);
    });
};

//////////////////////////////////////////////////////////////subscriptions
export const GetSubscriptionTypes = (payload, successCallBack) => {
  mainUrl
    .get(`category/subscription`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('class details', error);
    });
};

export const GetAllSubscriptions = (payload, successCallBack) => {
  mainUrl
    .post(
      `subscription`,
      { ...payload.data },
      {
        headers: {
          country: payload.countryID,
          'Accept-Language': i18n.language,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('class details', error);
    });
};

export const GetSubscriptionDetails = (payload, successCallBack) => {
  mainUrl
    .get(`subscription/vendor-details`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('class details', error);
    });
}

export const GetSubscriptionOrderReview = (payload, successCallBack) => {
  mainUrl
    .get(`subscription/proceed-to-booking`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('class details', error);
    });
};

export const SubscriptionProceedToPayment = (payload, successCallBack) => {
  mainUrl
    .post(
      `subscription/proceed-to-payment`,
      { ...payload.data },
      {
        headers: {
          country: payload.countryID,
          'Accept-Language': i18n.language,
            'Accept-Language':i18n.language,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('class details', error);
    });
};

export const GetSubscriptionHistory = (payload, successCallBack) => {
  mainUrl
    .get(`subscription/booking-history`, {
      headers: {
        Authorization: payload.Authorization,
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('sports history', error);
    });
};

//////////////////////////////////////// store
export const SendReqHome = (payload, successCallBack) => {
  mainUrl
    .get(`store`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      ...(payload.data
        ? {
            params: {
              ...payload.data,
            },
          }
        : {}),
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store home', error);
    });
};

export const GetStoreCategories = (payload, successCallBack) => {
  mainUrl
    .get(`store/categories`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store home', error);
    });
};

export const GetVendorDetails = (payload, successCallBack) => {
  mainUrl
    .get(`store/vendor/details`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store home', error);
    });
};

export const GetVendorItemsCategories = (payload, successCallBack) => {
  mainUrl
    .get(`store/vendor/categories`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store home', error);
    });
};

export const GetVendorCatItems = (payload, successCallBack) => {
  mainUrl
    .get(`store/vendor/items`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store home', error);
    });
};

export const GetProductDetails = (payload, successCallBack) => {
  mainUrl
    .get(`store/vendor/item-details`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store home', error);
    });
};

export const GetAllProducts = (payload, successCallBack) => {
  mainUrl
    .get(`store/products`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store home', error);
    });
};

export const GetAllVendors = (payload, successCallBack) => {
  mainUrl
    .get(`store/vendors`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: {
        ...payload.data,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store home', error);
    });
};

export const AddToCart = (payload, successCallBack) => {
  mainUrl
    .post(
      `cart/store`,
      { ...payload.data },
      {
        headers: {
          country: payload.countryID,
          'Accept-Language': i18n.language,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store home', error);
    });
};

export const GetShoppingCart = (payload, successCallBack) => {
  mainUrl
    .get(`cart`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: { ...payload.data },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store cart', error);
    });
};

export const RemoveFromCart = (payload, successCallBack) => {
  mainUrl
    .post(
      `cart/clear/item`,
      { ...payload.data },
      {
        headers: {
          country: payload.countryID,
          'Accept-Language': i18n.language,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store cart', error);
    });
};

export const IncDecCartItem = (payload, successCallBack) => {
  mainUrl
    .post(
      `cart/update`,
      { ...payload.data },
      {
        headers: {
          country: payload.countryID,
          'Accept-Language': i18n.language,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store cart', error);
    });
};

// store History
export const GetStoreHistory = (payload, successCallBack) => {
  mainUrl
    .get(`store/order-history`, {
      headers: {
        Authorization: payload.Authorization,
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store details', error);
    });
};

// store History
export const SaveAddress = (payload, successCallBack) => {
  mainUrl
    .post(
      `address/store`,
      { ...payload.data },
      {
        headers: {
          country: payload.countryID,
          'Accept-Language': i18n.language,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store details', error);
    });
};

// get cart Address
export const GetAddress = (payload, successCallBack) => {
  mainUrl
    .get(`address/show`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
      params: { ...payload.data },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store details', error);
    });
};

// get payment methods
export const GetPaymentMethods = (payload, successCallBack) => {
  mainUrl
    .get(`store/payment-methods`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store details', error);
    });
};

export const StorePlaceOrder = (payload, successCallBack) => {
  mainUrl
    .post(
      `store/place-order`,
      { ...payload.data },
      {
        headers: {
          country: payload.countryID,
          'Accept-Language': i18n.language,
            'Accept-Language':i18n.language
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store details', error);
    });
};

export const GetStaticPage = (payload, successCallBack) => {
  mainUrl
    .get(`pages`, {
      headers: {
        country: payload.countryID,
        'Accept-Language': i18n.language,
      },
    })
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store details', error);
    });
};

export const ForgetPass = (payload, successCallBack) => {
  mainUrl
    .post(
      `forget-password`,
      { ...payload.data },
      {
        headers: {
          country: payload.countryID,
          'Accept-Language': i18n.language,
        },
      }
    )
    .then((response) => {
      if (successCallBack != null) {
        let data = response.data;
        successCallBack(data);
      }
    })
    .catch((error) => {
      console.log('store details', error);
    });
};
