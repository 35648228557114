import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../Components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { changeLocale, SaveUserDataAction } from '../../Redux/Actions';
import ModuleCard from './ModuleCard';
import Carousel from 'react-material-ui-carousel';
import GeneralCarousel from '../../Components/GeneralCarousel';
import Select from 'react-select';
import { first, filter, map } from 'lodash';

export default function Content(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const { Lang, Country } = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const countries = map(props.Countries.data, (c) => {
    return {
      id: c.id,
      value: c.id,
      label: t(c.name),
    };
  });

  const [AllValid, SetAllValid] = useState(false);
  const [SelectedData, SetSelectedData] = useState({
    CategoryId: '',
    Category: '',
    Lang: Lang  ?? '',
    Country: Country
      ? Country
      : props.Countries.data[0],
  });

  const options = [
    {
      value: 'en',
      label: t('English').toString(),
    },
    {
      value: 'ar',
      label: t('Arabic').toString(),
    },
  ];

  // handel category selection
  const HandelCatSelection = (CatId) => {
    console.log(CatId);
    switch (CatId) {
      case 4:
        SetSelectedData((prev) => ({
          ...prev,
          Category: 'Store',
          CategoryId: CatId,
        }));
        break;

      case 1:
        SetSelectedData((prev) => ({
          ...prev,
          Category: 'Sports',
          CategoryId: CatId,
        }));
        break;

      case 2:
        SetSelectedData((prev) => ({
          ...prev,
          Category: 'Classes',
          CategoryId: CatId,
        }));
        break;

      case 3:
        SetSelectedData((prev) => ({
          ...prev,
          Category: 'Subscriptions',
          CategoryId: CatId,
        }));
        break;

      case 5:
        SetSelectedData((prev) => ({
          ...prev,
          Category: 'Event',
          CategoryId: CatId,
        }));
        break;

      default:
        break;
    }
  };

  useEffect(()=>{
    SetSelectedData((prev) => ({ ...prev, Lang: Lang }));
  },[Lang])

  // change lang on selection
  const HandelLangSelection = (lang) => {
    SetSelectedData((prev) => ({ ...prev, Lang: lang }));
    return dispatch(changeLocale(lang));
  };

  // on select country
  const HandelCountrySelection = (id) => {
    const Country = first(filter(props.Countries.data, (o) => o.id === id));
    SetSelectedData((prev) => ({
      ...prev,
      Country,
    }));
  };

  useEffect(() => {
    if (
      SelectedData.Category !== '' &&
      SelectedData.Lang !== '' &&
      SelectedData.Country !== ''
    ) {
      SetAllValid(true);
    }
  }, [SelectedData]);

  // when all selected navigate
  useEffect(() => {
    if (AllValid === true) {
      handelSave_Navigate();
    }
  }, [AllValid]);

  // const handelcontinue = () => {
  //   if (AllValid === true) {
  //     handelSave_Navigate()

  //   }
  // }

  const handelSave_Navigate = () => {
    dispatch(SaveUserDataAction({ ...UserSelectedData, ...SelectedData }));

    switch (SelectedData.CategoryId) {
      case 1:
        navigate('/sports');
        break;

      case 2:
        navigate('/classes');
        break;

      case 3:
        navigate('/Subscriptions');
        break;

      case 4:
        navigate('/Store');
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    console.log(SelectedData,AllValid);
  }, [SelectedData,AllValid]);

  return (
    <div className="flex flex-col gap-y-5 h-full">
      {/* centered title */}
      {/* <div className='flex justify-center w-full'>
        <p className='w-3/5'>{t("Select a Category of Formand continue")}</p>
      </div> */}

      <div>
        {/* slider */}
        <GeneralCarousel slidesArray={props.ModuleActiveTypes.data.slider} />

        {/* modules */}
        <div className="grid grid-cols-2 gap-x-5 gap-y-6  py-5">
          {props.ModuleActiveTypes.data.types.map((item) => {
            return (
              <ModuleCard
                key={item.id}
                cardClassName={
                  SelectedData.CategoryId === item.id &&
                  'border border-primary_BG'
                }
                onClick={() => HandelCatSelection(item.id)}
                ModuleName={item.name}
                ModuleImg={item.image}
                active={SelectedData.CategoryId === item.id ? true : false}
              />
            );
          })}
        </div>
      </div>

      <div
        className="grid grid-cols-2 gap-x-5 gap-y-6  py-5"
        dir={i18n.dir().toString()}
      >
        {/* select language */}
        <Select
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              color: `white`,
            }),
          }}
          className="react-select-container"
          classNamePrefix="react-select"
          options={options}
          onChange={(e) => HandelLangSelection(e.value)}
          defaultValue={
            first(filter(options, (o) => o.value === SelectedData.Lang)) ?? first(options)
          }
          isRtl={Lang === 'ar'}
        />

        <Select
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              color: `white`,
            }),
          }}
          className="react-select-container"
          classNamePrefix="react-select"
          options={countries}
          onChange={(e) => HandelCountrySelection(e.id)}
          defaultValue={first(
            filter(countries, (o) => o.value === SelectedData.Country.id)
          )}
          isRtl={Lang === 'ar'}
        />
      </div>

      {/* continue btn */}
      {/* <div className='my-5'>
        <CustomButton
          ContainerClass={`w-full ${AllValid === true ? 'bg-primary_BG' : 'bg-GreyBG'} rounded-xl text-white py-2`}
          ButtonClass={AllValid === true ? 'bg-primary_BG' : 'bg-GreyBG'}
          onclick={() => handelcontinue()}
          BtnValue={t("Continue")}
        />
      </div> */}
    </div>
  );
}
