import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackBTN from '../../../Components/BackBTN';
import SectionTitle from '../../Venues/Venue Details/SectionTitle';

import { ReactComponent as Calender } from '../../../Assets/Icons/Calender.svg';
import { ReactComponent as Aminities } from '../../../Assets/Icons/Aminities.svg';
import { ReactComponent as Location } from '../../../Assets/Icons/Location.svg';
import { ReactComponent as LocationMarker } from '../../../Assets/Icons/LocationMarker.svg';

import GoogleMapReact from 'google-map-react';
import CustomButton from '../../../Components/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  SaveUserDataAction,
  ToggleShowHideAction,
} from '../../../Redux/Actions';
import GreyLine from '../../../Components/GreyLine';
import SelectSubscriptionDate from '../SelectDate/SelectDate';

export default function Content(props) {
    const {t}=useTranslation()
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const UserSelectedData = useSelector(state => state.PersistedReducer.UserSelectedData)
    // const [SelectedSubscription,SetSelectedSubscription]=useState(UserSelectedData.SelectedSubscription ? UserSelectedData.SelectedSubscription :{})
    const [SelectedSubscription,SetSelectedSubscription]=useState(()=>{
      if(UserSelectedData.SelectedSubscription){
        let selected={}
        for (let i = 0; i < props.Data.data.subscriptions.length; i++) {
            if (props.Data.data.subscriptions[i].id === UserSelectedData.SelectedSubscription.id) {
                selected=UserSelectedData.SelectedSubscription;              
            }
        }
        return selected
      }
      else{
        return {}
      }
        
    })

  const [ShowDateModal, SetShowDateModal] = useState(false);

  // save selected subscription
  const SaveSelectedSubscription = (sub) => {
    dispatch(
      SaveUserDataAction({
        ...UserSelectedData,
        SelectedSubscription: sub,
      })
    );
  };

  // on click continue
  const HandelContinue = () => {
    if (SelectedSubscription.id) {
      SaveSelectedSubscription(SelectedSubscription);

            // if(UserSelectedData.SelectedSubscriptionDate){
            //     navigate('/OrderReview')
            // }
            // else{
            //     SetShowDateModal(true)
            // }  
            SetShowDateModal(true)
        }
        else{
            dispatch(ToggleShowHideAction({
                Open:true,
                Type:'info',
                Msg:t("Please select a Subsceiption to continue ")
            }))     
        }
    }


    useEffect(()=>{
        console.log(SelectedSubscription)
    },[SelectedSubscription])


  return (
    <div>
      <SelectSubscriptionDate
        open={ShowDateModal}
        onclose={() => SetShowDateModal(false)}
      />

      <BackBTN />
      <div className="my-2">
        <img
          src={props.Data.data.vendor_banner}
          className="h-28 w-full rounded-md"
        />
      </div>

        <div className='grid grid-cols-2 mb-4'>
            <div className='flex p-px'>
                <img className='w-10 h-10 rounded-md' 
                src={props.Data.data.vendor_logo}
                // src={require('../../Assets/Images/img-not-found-thumbNail.png')}
                onError={(e) => {
                    e.onerror = null; // prevents looping
                    e.target.src=require('../../../Assets/Images/img-not-found-thumbNail.png');
                }}
                />
                <div className='text-start mx-1'>
                    <p className=''>{props.Data.data.vendor_name}</p>
                    <p className='text-xxs'>{props.Data.data.area}</p>
                </div>
            </div>

            <div className='flex flex-col justify-between items-end h-full'>
                <p className='text-primary_BG'>{props.Data.data.price} {props.Data.data.currency}</p>
                <p className='bg-SearchGrey rounded-xl px-1.5 text-xxs'>{props.Data.data.space}</p>
            </div>

            {/* description */}
            <div className='flex justify-start col-span-2 text-sm mt-3'>
                <p>{props.Data.data.description}</p>
            </div>
        </div>

        <GreyLine className='mb-5'/>


      {/* categories */}
      <SectionTitle
        icon={<Aminities width="15.703" height="15.487" />}
        title={t('Categories')}
      />

      {props.Data.data.categories ? (
        <div className="flex justify-start bg-LighterGray rounded-md p-2 mt-1 mb-7">
          <p className="w-fit text-sm">{props.Data.data.categories}</p>
        </div>
      ) : (
        <div className="flex justify-center bg-LighterGray rounded-md p-2 mt-1 mb-7">
          <p className="w-fit text-sm">No Categories Found</p>
        </div>
      )}

      {/* subs */}
      <SectionTitle
        icon={<Calender width="15.703" height="15.487" />}
        title={t('Subscriptions')}
      />


        {props.Data.data.subscriptions.length === 0 ? 
            <div className='flex justify-center text-sm my-3'>
                <p>{t("No Subscriptions Found")}</p>
            </div>
            :
            <div className='mb-7'>
                {props.Data.data.subscriptions.map(itm=>{
                    return(
                        <div key={itm.id} className='flex justify-between text-sm gap-x-2 py-3'>
                            <div className='flex items-start'>
                                <input checked={SelectedSubscription.id === itm.id} onClick={()=>{SetSelectedSubscription(itm)}} className='mx-2 mt-1' type='radio' name='subscription'/>
                                <div className='flex flex-col items-start'>
                                    <p onClick={()=>{SaveSelectedSubscription(itm); navigate('/SubscriptionDetails')}} className='text-primary_BG cursor-pointer'>{itm.name}</p>
                                    <p>{itm.subscription_duration}</p>
                                </div>
                            </div>
                            <p className='text-primary_BG'>{itm.price} {props.Data.data.currency}</p>                       
                        </div>
                    )
                })}
            </div>
        }
        



        {SelectedSubscription.id &&
            <>
                {/* aminities */}
                <SectionTitle
                icon={<Aminities width="15.703" height="15.487" />}
                title={t("Amenities")}
                />

                <div className='grid grid-flow-col gap-x-2 gap-y-3 mt-1 mb-7 bg-LighterGray rounded-md p-2 h-24'>
                    {SelectedSubscription.amenities.map(itm=>{
                        return(
                            <div key={itm.id} className='flex flex-col items-center justify-center'>
                                <img
                                className='h-10 w-10' 
                                alt={itm.name} 
                                src={itm.icon}
                                onError={(e) => {
                                    e.onerror = null; // prevents looping
                                    e.target.src=require('../../../Assets/Images/img-not-found-thumbNail.png');
                                }}
                                />

                                <p className='text-primary_BG text-xs py-1'>{itm.name}</p>
                            </div>
                        )
                    })}
                </div>


                {/* location */}
                <SectionTitle
                icon={<Location width="9.857" height="14.899"  />}
                title={t("Location")}
                />

                <div className='h-32 mb-3'>
                    <GoogleMapReact
                    className='rounded-md'
                    bootstrapURLKeys={{
                        // remove the key if you want to fork
                        key: "AIzaSyChibV0_W_OlSRJg2GjL8TWVU8CzpRHRAE",
                        language: "en",
                        region: "US"
                    }}
                    defaultCenter={
                        {
                            lat: SelectedSubscription.latitude ? parseInt(SelectedSubscription.latitude) : 10.99835602 ,
                            lng: SelectedSubscription.longitude ? parseInt(SelectedSubscription.longitude) : 77.01502627
                        }
                    }
                    defaultZoom={11}
                    >
                        <LocationMarker  width="20.601" height="31.664"
                            lat={SelectedSubscription.latitude ? SelectedSubscription.latitude : 10.99835602}
                            lng={SelectedSubscription.longitude ? SelectedSubscription.longitude : 77.01502627}
                        />
                    </GoogleMapReact>
                </div>
            </>
        }    


        {/* continue btn */}
        <div className='py-5'>
        <CustomButton onclick={()=>{HandelContinue()}} BtnValue={t("Continue")} ContainerClass='bg-primary_BG rounded-xl text-sm text-white cursor-pointer py-1'/>
        </div>

    </div>
  )
}
