import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackBTN from '../../Components/BackBTN';
import CustomButton from '../../Components/CustomButton';
import {
  IsGuestUserAction,
  SaveUserInfoAction,
  ToggleShowHideAction,
} from '../../Redux/Actions';
import { ExecuteBasedOnCat } from '../../Utils/Utils';

export default function Content() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [SelectedData, SetSelectedData] = useState({
    IsGuest: true,
    guest_name: '',
    guest_phone: '',
    guest_gender: '',
  });

  // set val when inpt chng
  const HandelInputChange = (val, nm) => {
    SetSelectedData((prev) => ({
      ...prev,
      [nm]: val,
    }));
  };

  // login save data
  const HandelLogin = () => {
    if (
      SelectedData.guest_name !== '' &&
      SelectedData.guest_gender !== '' &&
      SelectedData.guest_phone !== ''
    ) {
      if (SelectedData.guest_phone.length < 8) {
        dispatch(
          ToggleShowHideAction({
            Open: true,
            Type: 'error',
            Msg: 'Please Enter a Valid Phone',
          })
        );
      } else {
        dispatch(SaveUserInfoAction({ ...SelectedData }));
        dispatch(IsGuestUserAction(true));

        // navigate based on cat
        ExecuteBasedOnCat(
          UserSelectedData.CategoryId,
          () => navigate('/ReviewOrder'),
          () => navigate('/OrderReview'),
          () => navigate('/OrderReview'),
          () => navigate('/ReviewOrder/Store')
        );
      }
    } else {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: t('name and phone and gender are required '),
        })
      );
    }
  };

  useEffect(() => {
    console.log(SelectedData);
  }, [SelectedData]);

  return (
    <div>
      <BackBTN
        text={
          <div className="flex justify-center">
            <p className="w-1/2">{t('Register to Form and continue')}</p>
          </div>
        }
      />

      <input
        onChange={(e) => {
          HandelInputChange(e.target.value, 'guest_name');
        }}
        type="text"
        placeholder={t('Name')}
        className="rounded-lg px-3 py-1 mb-5 text-sm bg-gray outline-none w-full"
      />

      <input
        onChange={(e) => {
          HandelInputChange(e.target.value, 'guest_phone');
        }}
        type="tel"
        placeholder={t('Mobile Number')}
        className="rounded-lg px-3 py-1 mb-5 text-sm bg-gray outline-none w-full"
      />

      {/* gender */}
      <div className="grid grid-cols-2 justify-items-center gap-x-10">
        <CustomButton
          onclick={() => {
            HandelInputChange('male', 'guest_gender');
          }}
          // ContainerStyle={{maxWidth:'10rem'}}
          ContainerClass={
            SelectedData.guest_gender === 'male'
              ? 'w-full border border-SearchGrey rounded-lg text-sm cursor-pointer py-1 bg-primary_BG text-white'
              : 'w-full border border-SearchGrey rounded-lg text-sm cursor-pointer py-1 '
          }
          BtnValue={t('Male')}
        />

        <CustomButton
          onclick={() => {
            HandelInputChange('female', 'guest_gender');
          }}
          // ContainerStyle={{maxWidth:'10rem'}}
          ContainerClass={
            SelectedData.guest_gender === 'female'
              ? 'w-full border border-SearchGrey rounded-lg text-sm cursor-pointer py-1 bg-primary_BG text-white'
              : 'w-full border border-SearchGrey rounded-lg text-sm cursor-pointer py-1 '
          }
          BtnValue={t('Female')}
        />
      </div>

      <CustomButton
        onclick={() => HandelLogin()}
        ContainerClass="bg-primary_BG rounded-lg text-sm text-white py-1 my-5"
        BtnValue={t('Continue')}
      />
    </div>
  );
}
